import { template as template_6af3dc599ecc4449bfe5afa64335bc30 } from "@ember/template-compiler";
const FKLabel = template_6af3dc599ecc4449bfe5afa64335bc30(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
