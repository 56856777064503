import { template as template_509531ed3f4d4d4aa67694faeedba70c } from "@ember/template-compiler";
const FKControlMenuContainer = template_509531ed3f4d4d4aa67694faeedba70c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
